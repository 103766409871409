import React from 'react'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '5%',
    //backgroundColor: theme.palette.background.secondary,
  },
  info: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '5%',
    paddingBottom: '5%',
    textAlign: 'left',
  },
}))

const Footer = (props) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div>
      <BottomNavigation showLabels>
        <BottomNavigationAction
          label="Privacy Policy"
          onClick={() => history.push('/privacy')}
        />
        {/* <BottomNavigationAction label="Terms of Service" /> */}
      </BottomNavigation>
    </div>
  )
}

export default Footer
