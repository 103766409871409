import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { themes } from '../Constants/themes'

export const ThemeProviderWrapper = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: prefersDarkMode ? themes.dark.palette : themes.light.palette,
      }),
    [prefersDarkMode],
  )

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
export default ThemeProviderWrapper
