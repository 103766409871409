import React from 'react'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import SendIcon from '@mui/icons-material/Send'

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.toolbar.backgroundColor,
    color: theme.palette.toolbar.color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.primary,
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, onSend, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 1.6 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 10,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {onSend ? (
        <IconButton
          aria-label="close"
          onClick={onSend}
          sx={{
            position: 'absolute',
            right: 10,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <SendIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function MessageDialog(props) {
  const [text, setText] = React.useState(props.defaultMessageText || '')
  const classes = useStyles()

  const handleChange = (event) => {
    setText(event.target.value)
  }

  const handleSend = () => {
    setText('')
    props.handleOnSend(text)
  }

  return (
    <div>
      <Dialog fullScreen open={props.isOpen || false}>
        <BootstrapDialogTitle
          onClose={props.handleOnClose}
          onSend={handleSend}
          className={classes.title}
        >
          {props.title}
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ paddingTop: 5 }}
          className={classes.content}
        >
          <TextField
            id="outlined-multiline-flexible"
            label="Notification"
            multiline
            minRows={4}
            maxRows={20}
            value={text}
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
