import React, { useState } from 'react'
//import { makeStyles } from '@material-ui/core/styles'
import { makeStyles } from '@mui/styles'

//import Grid from '@material-ui/core/Grid'
import Grid from '@mui/material/Grid'

import LocalShippingIcon from '@mui/icons-material/LocalShipping'
//import MailIcon from '@material-ui/icons/Mail'
import MailIcon from '@mui/icons-material/Mail'

import FastfoodIcon from '@mui/icons-material/Fastfood'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore'
import MessageIcon from '@mui/icons-material/Message'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import Tile from './Tile'
import MessageDialog from './Dialog'
import axios from 'axios'
import { baseUrl } from './Helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 16,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    //color: theme.palette.text.secondary,
  },
}))

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const TileMenu = (props) => {
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState('')
  const [defaultMessageText, setDefaultMessageText] = useState('')
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('')
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const classes = useStyles()

  const handleSnackbar = (severity) => {
    if (severity === 'success') {
      setSnackbarMessage('Successfully sent notification!')
    } else if (severity === 'error') {
      setSnackbarMessage('Failed to send notification')
    } else {
      setSnackbarMessage('')
    }

    setSnackbarSeverity(severity)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false)
  }

  const handleTileClick = (item, defaultText) => {
    setSelectedItem(item)
    setDefaultMessageText(defaultText)
    setIsMessageDialogOpen(true)
  }

  const handleMessageDialogClose = () => {
    setIsMessageDialogOpen(false)
  }

  const handleMessageDialogSend = (text) => {
    handleMessageDialogClose()
    sendPushNotification(text)
  }

  const sendPushNotification = async (text) => {
    await axios
      .post(baseUrl() + '/notification/send', {
        code: props.code,
        body: text,
        title: 'QRHome Notification - ' + selectedItem,
      })
      .then((res) => {
        handleSnackbar('success')
      })
      .catch(function (error) {
        handleSnackbar('error')
      })
  }

  const renderMessageDialog = () => {
    return (
      <MessageDialog
        isOpen={isMessageDialogOpen}
        handleOnClose={handleMessageDialogClose}
        handleOnSend={handleMessageDialogSend}
        title={selectedItem}
        defaultMessageText={defaultMessageText}
      />
    )
  }

  const renderTiles = () => {
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Tile
              handleOnClick={handleTileClick}
              IconImage={LocalShippingIcon}
              iconText={'Package'}
              defaultMessage={'Your package has been delivered.'}
            />
          </Grid>
          <Grid item xs={6}>
            <Tile
              handleOnClick={handleTileClick}
              IconImage={MailIcon}
              iconText={'Mail'}
              defaultMessage={'Your mail has been delivered.'}
            />
          </Grid>
          <Grid item xs={6}>
            <Tile
              handleOnClick={handleTileClick}
              IconImage={FastfoodIcon}
              iconText={'Food'}
              defaultMessage={'Your food has been delivered.'}
            />
          </Grid>
          <Grid item xs={6}>
            <Tile
              handleOnClick={handleTileClick}
              IconImage={LocalGroceryStoreIcon}
              iconText={'Grocery'}
              defaultMessage={'Your groceries have been delivered.'}
            />
          </Grid>
          <Grid item xs={6}>
            <Tile
              handleOnClick={handleTileClick}
              IconImage={NotificationsActiveIcon}
              iconText={'Ring'}
              defaultMessage={'Ding Dong.'}
            />
          </Grid>
          <Grid item xs={6}>
            <Tile
              handleOnClick={handleTileClick}
              IconImage={MessageIcon}
              iconText={'Custom'}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  if (isMessageDialogOpen) {
    return renderMessageDialog()
  } else {
    return renderTiles()
  }
}

export default TileMenu
