import './App.css'
import ButtonAppBar from './Components/Toolbar'
import Notification from './Components/Notification'
import Home from './Components/Home'
import Privacy from './Components/Privacy'
import { makeStyles } from '@mui/styles'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    textAlign: 'center',
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
  },
}))

function App() {
  const classes = useStyles()
  return (
    <Router>
      <div className={classes.root}>
        <ButtonAppBar />
        <Switch>
          <Route path="/notification" component={Notification} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/" component={Home} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
