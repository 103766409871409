import React from 'react'
//import ControlledAccordions from './Accordion'
import TileMenu from './TileMenu'

const queryString = require('query-string')

class Notification extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount = () => {}

  getId = () => {
    let queryParams = queryString.parse(this.props.location.search)
    return queryParams.id
  }

  render() {
    return (
      <>
        <div style={{ marginTop: 35, marginBottom: 35 }}>
          <h3>Send Notification</h3>
        </div>
        {/* <ControlledAccordions code={this.getId()} /> */}
        <TileMenu code={this.getId()} />
      </>
    )
  }
}

export default Notification
