import React from 'react'
import Footer from './Footer'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    //backgroundColor: theme.palette.background.secondary,
    paddingTop: '5%',
  },
  footer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    //marginTop: '100vh',
  },
}))

export default function Home() {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <p>Welcome to QRHome</p>
        <p>Download the android app to setup an account</p>
      </div>
      <Footer className={classes.footer} />
    </>
  )
}
