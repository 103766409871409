export const themes = {
  dark: {
    palette: {
      background: {
        primary: '#282c34',
        secondary: '#343a40',
      },
      text: {
        primary: '#f8f9fa',
        secondary: '#e9ecef',
      },
      toolbar: {
        backgroundColor: '#212529',
        color: '#e9ecef',
      },
    },
  },
  light: {
    palette: {
      background: {
        primary: '#f8f9fa',
        secondary: '#e9ecef',
      },
      text: {
        primary: '#343a40',
        secondary: '#495057',
      },
      toolbar: {
        backgroundColor: '#343a40',
        color: '#e9ecef',
      },
    },
  },
}
