import React from 'react'
import { makeStyles } from '@mui/styles'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import HomeIcon from '@mui/icons-material/Home'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.toolbar.color,
  },
  title: {
    flex: 1,
    paddingRight: 35,
    color: theme.palette.toolbar.color,
  },
  toolbar: {
    backgroundColor: theme.palette.toolbar.backgroundColor,
  },
}))

export default function ButtonAppBar() {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <HomeIcon
            className={classes.menuButton}
            onClick={() => history.push('/')}
          />
          <Typography variant="h6" className={classes.title}>
            QRHome
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}
