import React from 'react'
//import { makeStyles } from '@material-ui/core/styles'
import { makeStyles } from '@mui/styles'
//import Paper from '@material-ui/core/Paper'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    // '& > *': {
    //   height: theme.spacing(16),
    //   //backgroundColor: '#343a40',
    //   //backgroundColor: theme.palette.background.secondary,
    // },
    //flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 3,
    height: 130,
    backgroundColor: theme.palette.background.secondary,
  },
  // icon: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   //color: theme.palette.text.primary,
  //   //backgroundColor: '#343a40',
  // },
  iconText: {
    margin: 0,
  },
}))

const Tile = (props) => {
  const classes = useStyles()
  const { IconImage, iconText, defaultMessage } = props
  return (
    <div>
      {/* <Paper
        //component={ButtonBase}
        // sx={{ height: theme.spacing(16)backgroundColor: '#343a40' }}
        //root={'#343a40'}
        className={classes.icon}
        elevation={3}
        onClick={() => {
          props.handleOnClick(iconText, defaultMessage)
        }}
      > */}
      <Box
        className={classes.root}
        sx={{ boxShadow: 3 }}
        onClick={() => {
          props.handleOnClick(iconText, defaultMessage)
        }}
      >
        <div>
          <IconImage fontSize="large" />
          <p className={classes.iconText}>{iconText}</p>
        </div>
        {/* </Paper> */}
      </Box>
    </div>
  )
}

export default Tile
